@font-face {
  font-family: "SF Pro Display";
  src: url("../public/fonts/SFProDisplay-Bold.woff2") format("woff2"),
    url("../public/fonts/SFProDisplay-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../public/fonts/SFProDisplay-Regular.woff2") format("woff2"),
    url("../public/fonts/SFProDisplay-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../public/fonts/SFProDisplay-Medium.woff2") format("woff2"),
    url("../public/fonts/SFProDisplay-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: "SF Pro Display" !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: normal !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #292929 !important;
  color: #fff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
}
input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
}
input::placeholder {
  color: rgba(255, 255, 255, 0.3);
}

input.error::-webkit-input-placeholder {
  color: #ff9a9a;
}
input.error:-ms-input-placeholder {
  color: #ff9a9a;
}
input.error::placeholder {
  color: #ff9a9a;
}

.site-input {
  margin-bottom: 20px;
  position: relative;
}

.site-input input[type="password"],
.site-input input[type="email"],
.site-input input[type="text"],
.site-input input[type="tel"],
.site-input input[type="file"],
.site-input input[type="number"],
.site-input textarea {
  border-radius: 20px;
  border: 2px solid rgba(254, 251, 234, 0.2);
  width: 100%;
  outline: none;
  box-shadow: none;
  background: none;
  padding: 0 18px;
  font-size: 21px;
  font-weight: 400;
  height: 58px;
  color: rgba(255, 255, 255, 0.8);
}

.site-input textarea {
  height: 100px;
  padding-top: 10px;
  padding-bottom: 30px;
}

.input-left-text .left-text {
  position: absolute;
  top: 16px;
  left: 20px;
  color: rgba(255, 255, 255, 0.4);
  font-size: 21px;
  font-weight: 400;
}

.input-left-text input {
  padding-left: 60px !important;
}

.site-btn {
  color: #fff;
  font-size: 21px;
  background: transparent;
  outline: none;
  border-radius: 30px;
  border: 0.5px solid #fefbea;
  padding: 10px 16px;
  font-weight: 400;
  display: inline-block;
  text-decoration: none;
}

.text-underline {
  text-decoration: underline !important;
}

.max-100 {
  max-width: 100% !important;
}

.clr-primary {
  color: #ff8b3d !important;
}

.site-heading {
  text-align: center;
}

.site-heading h1 {
  font-size: 50px;
  font-weight: 700;
  margin: 0 0 0;
}

.site-heading h2 {
  color: #fff;
  font-size: 35px;
  font-weight: 500;
  margin: 24px 0 0;
}

.text {
  color: rgba(255, 255, 255, 0.7);
  font-size: 15px;
  font-weight: 500;
  font-family: "Roboto", serif;
}

.clr-danger {
  color: #ff9a9a !important;
}

body .h2 {
  color: #fff;
  font-size: 28px;
  font-family: "Roboto", serif;
  font-weight: 500;
  margin: 0 0 0;
}
body .h4 {
  color: #fff;
  font-size: 16px;
  font-family: "Roboto", serif;
  font-weight: 500;
  margin: 0 0 0;
}

/* Custom Switch Styles */
.custom-switch {
  position: relative;
  display: inline-block;
  width: 59.629px;
  height: 26.833px;
}
.custom-switch input {
  /* opacity: 0;
  width: 0;
  height: 0; */
  display: none;
}
.custom-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 26.833px;
}
.custom-switch .slider:before {
  position: absolute;
  content: "";
  height: 20.833px;
  width: 20.833px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}
.custom-switch input:checked + .slider {
  background-color: #ff8b3d;
}
.custom-switch input:checked + .slider:before {
  transform: translateX(32.796px);
}

.container {
  max-width: 1070px !important;
}

.main-site-wrapper {
  background: #1f1f1f;
  min-height: 100vh;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
}

/* AUTH SCREENS STYLING */

.auth-scree-wrapper {
  background: #2b2b2b;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
}

.auth-scree-wrapper::after {
  content: "";
  background: radial-gradient(
    50.79% 50.79% at 50% 49.21%,
    rgba(255, 139, 61, 0.1) 0%,
    rgba(255, 139, 61, 0) 100%
  );
  border-radius: 1391px;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: -50%;
  left: 0;
  right: 0;
  z-index: 1;
}

.auth-scree-inner {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 447px;
}

.auth-main-box {
  width: 100%;
  border-radius: 32px;
  border: 0.5px solid rgba(254, 251, 234, 0.2);
  background: rgba(59, 59, 59, 0.48);
  backdrop-filter: blur(20px);
  text-align: center;
  padding: 30px;
}

.auth-main-box h2 {
  color: #fff;
  margin: 0 0 0;
  font-size: 26px;
  font-weight: 500;
  padding-bottom: 43px;
}

.simple-link-text {
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  display: inline-block;
}

.or-gap {
  color: #fff;
  font-size: 21px;
  font-weight: 400;
  display: block;
}

/* .third-party-btn {
  background: #fff;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px;
  color: #000;
  font-size: 22px;
  font-weight: 500;
  text-decoration: none;
}

.third-party-btn img {
  display: inline-block;
  margin-right: 7px;
  max-width: 15px;
} */

.third-party-btn {
  background: #fff;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px;
  color: #000;
  font-size: 22px;
  font-weight: 500;
  text-decoration: none;
  border: 1px solid #ddd;
  cursor: pointer;
  position: relative;
}

.third-party-btn img {
  display: inline-block;
  margin-right: 10px;
  max-width: 20px;
}

.third-party-btn:hover {
  background: #f1f1f1;
}

/* Overlay Google Login on top of the button */
.google-login-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0; /* Make it invisible */
  cursor: pointer;
}
.anchor-link-bottom {
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  font-size: 20px;
  font-weight: 400;
  padding-top: 24px;
}

.anchor-link-bottom a {
  color: #ff8b3d;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}

.anchor-link-bottom h3 {
  font-size: 26px;
  color: #fff;
  margin: 0 0 0;
  font-weight: 400;
  padding-bottom: 22px;
}

/* AUTH SCREENS STYLING CLOSED */

/* HEADER STYLING */

.header-inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.header-inner .header-left {
  width: 250px;
}

.header-inner .header-left a {
  display: inline-block;
  text-decoration: none;
  line-height: 0;
}

.header-inner .header-left a img {
  max-width: 170px;
}

.header-inner .header-middle {
  width: calc(100% - 500px);
}

.header-inner .header-middle ul {
  justify-content: center;
}

.header-inner ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.header-inner ul li {
  padding: 0 20px;
}

.header-inner ul li p {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
  font-family: "Roboto", serif;
  transition: all 0.3s;
  margin-bottom: 0px;
  cursor: pointer;
}

.header-inner ul li p:hover {
  color: #ff8b3d;
  margin-bottom: 0px;
}

.header-right {
  width: 250px;
}

.header-right ul {
  /* justify-content: flex-end; */
}

.site-header .container-fluid {
  max-width: 1600px;
  padding: 0 30px;
}

.site-header {
  padding: 30px 0;
}

.header-mobile-toggle {
  display: none;
}

/* HEADER STYLING CLOSED */

/* FOOTER STYLING */

.site-footer {
  background: rgba(68, 68, 68, 0.6);
  backdrop-filter: blur(20.149999618530273px);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 111;
}

.footer-ads {
  background: #7d7d7d;
  backdrop-filter: blur(20.149999618530273px);
  height: 100px;
}

/* FOOTER STYLING CLOSED */

/* DISCOVER STYLE */

.discover-wrapper {
  padding: 50px 0;
}

.mesonate-card {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 24px;
  cursor: pointer;
}

.mesonate-card .mesonate-image img {
  object-fit: cover;
  width: 100%;
  display: block;
  height: 250px;
}

.mesonate-image {
  object-fit: cover;
}

.mesonate-card.tall-img .mesonate-image img {
  height: 500px;
}

.mesonate-top-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 15px;
}

.mesonate-label {
  display: inline-flex;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  border-radius: 45.932px;
  background: rgba(27, 26, 26, 0.5);
  backdrop-filter: blur(6.561670780181885px);
  padding: 6px 10px;
  margin-right: 10px;
}

.mesonate-label svg {
  width: 12px;
  display: inline-block;
  margin-right: 8px;
}

.mesonate-bottom-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 15px;
}

.mesonate-bottom-text h4 {
  margin: 0 0 0;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  text-shadow: 0px 5.249px 10.499px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 2;
}

.mesonate-bottom-text p {
  margin: 5px 0 0;
  color: #fff;
  text-shadow: 0px 5.249px 10.499px rgba(0, 0, 0, 0.25);
  font-size: 18px;
  font-weight: 400;
  position: relative;
  z-index: 2;
}

.mesonate-bottom-text::after {
  content: "";
  background-image: linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 1)
  );
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: block;
}

ul.mesonate-list {
  padding: 40px 0 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

ul.mesonate-list li {
  width: 25%;
  padding: 0 12px;
}

.custom-tabs-wrapper {
  padding: 100px 0 0px;
}

.custom-tabs-wrapper .nav-tabs {
  justify-content: center;
  border: none;
}

.custom-tabs-wrapper .nav-tabs .nav-item {
  padding: 20px 20px;
}

.custom-tabs-wrapper .nav-tabs .nav-item button {
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  background: transparent;
  border: none;
  padding: 5px 0;
  outline: none;
  display: inline-block;
  border-bottom: 1px solid transparent;
}

.custom-tabs-wrapper .nav-tabs .nav-item button.active {
  border-color: #ff8b3d;
}

ul.mesonate-list {
  padding: 40px 0 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

ul.mesonate-list li {
  width: 25%;
  padding: 0 12px;
}

.custom-tabs-wrapper .tab-content .nav-tabs {
  padding: 15px 0 0;
}

.custom-tabs-wrapper .tab-content .nav-tabs .nav-item {
  padding: 8px;
}

.custom-tabs-wrapper .tab-content .nav-tabs .nav-item button {
  border-radius: 34px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  font-size: 20px;
  font-weight: 400;
  padding: 6px 18px;
  display: inline-flex;
  align-items: center;
  font-family: "Roboto", serif;
}

.custom-tabs-wrapper .tab-content .nav-tabs .nav-item button img {
  display: inline-block;
  object-fit: contain;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.custom-tabs-wrapper .tab-content .nav-tabs .nav-item button.active {
  background: #ff8b3d;
  border-color: #ff8b3d;
}
.custom-tabs-wrapper .tab-content .nav-tabs .nav-item button.active span {
  color: #fff !important;
}

.blank-tabs-data {
  text-align: center;
  padding: 150px 0;
  max-width: 550px;
  margin: 0 auto;
  width: 100%;
}

.blank-tabs-data h3 {
  color: #fff;
  font-size: 25px;
  font-weight: 400;
  font-style: italic;
}

/* DISCOVER STYLE CLOSED */

/* MODAL STYLING */

.news-feed-modal .modal-content {
  scale: 98%;
  border-radius: 29.11px;
  background: #212121;
  box-shadow: 0px 2.708px 15.164px 0px rgba(0, 0, 0, 0.25);
}
.news-feed-modal .modal-header {
  border: none;
  padding: 0px 30px;
  margin-bottom: 20px;
}
.news-feed-modal .modal-header .modal-title h1 {
  color: #fff;
  margin: 0 0 0;
  font-size: 30.5px;
  font-weight: 500;
  font-family: "Roboto", serif;
}
/* .news-feed-modal .modal-header .modal-title small */
.modal-header-title-date small {
  color: rgba(255, 255, 255, 0.7);
  font-family: "Roboto", serif;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  margin-left: 10px;
  padding-bottom: 3px;
}
.news-feed-modal .modal-header .modal-title button,
.update-event-follow-button {
  color: #fff;
  text-align: center;
  font-family: "Roboto", serif;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  background: none;
  box-shadow: none;
  display: inline-block;
  text-decoration: none;
  border-radius: 21.76px;
  border: 0.64px solid rgba(255, 255, 255, 0.3);
  padding: 4px 15px;
}
.news-feed-modal .modal-header .btn-close {
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: 1;
  object-fit: contain;
  filter: invert(1) grayscale(100%) brightness(200%);
  background-size: 14px;
  outline: none;
  box-shadow: none;
}
.news-feed-modal .modal-content .modal-body {
  padding: 0px 30px 20px;
}
.news-feed-modal .news-title {
  margin: 0 0 0;
  color: #fff;
  font-family: "Roboto", serif;
  font-size: 20px;
  font-weight: 300;
}
.newsfeed-body ul {
  padding: 20px 0 0;
  margin: 0;
  list-style: none;
}
.newsfeed-body ul li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 19.632px;
  border: 0.677px solid rgba(255, 255, 255, 0.21);
  background: rgba(255, 255, 255, 0.02);
  padding: 20px 40px;
  margin-bottom: 20px;
}
.newsfeed-body ul li.newsfeed-ads {
  height: 155px;
  background: #d9d9d9;
  border: none;
  padding: 0;
  border-radius: 0;
}
.newsfeed-body ul li:last-child {
  margin-bottom: 0;
}
.newsfeed-body ul li .in-left {
  width: 140px;
  overflow: hidden;
}
.newsfeed-body ul li .in-left img {
  width: 100%;
  height: 140px;
  object-fit: cover;
  border-radius: 12px;
}
.newsfeed-body ul li .in-right {
  width: calc(100% - 140px);
  padding-left: 30px;
}
.newsfeed-body ul li .in-right h5 {
  color: rgba(255, 255, 255, 0.75);
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 0;
  font-family: "Roboto", serif;
}
.newsfeed-body ul li .in-right h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin: 8px 0 0;
  font-family: "Roboto", serif;
}
.newsfeed-body ul li .in-right p {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-weight: 400;
  margin: 8px 0 0;
  font-family: "Roboto", serif;
}
.newsfeed-body ul li .in-right .span-text {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  font-weight: 500;
  margin: 0 0 0;
  display: block;
  padding-top: 20px;
  font-family: "Roboto", serif;
}

/* MODAL STYLING CLOSED */

/* PROFILE STYLE */

.user-profile-wrapper {
  padding: 0 0 30px;
  width: 100%;
}

.user-profile-inner {
  max-width: 530px;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
}

.user-profile-list ul.mesonate-list li {
  width: 50%;
}

.user-profile-meta {
  border-radius: 16.114px;
  border: 0.6px solid rgba(255, 255, 255, 0.2);
  background: rgba(45, 45, 45, 0);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
  padding: 30px 20px;
  display: flex;
  align-items: center;
  position: relative;
}

.user-profile-meta .in-left {
  width: 135px;
}

.user-profile-meta .in-left img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.user-profile-meta .in-right {
  width: calc(100% - 135px);
  padding-left: 24px;
}

.profile-float-top {
  display: inline-flex;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  border-radius: 45.932px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(6.561670780181885px);
  padding: 6px 15px;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
}

.profile-float-top svg {
  width: 12px;
  display: inline-block;
  margin-right: 6px;
}

.profile-float-bottom {
  cursor: pointer;
  display: inline-flex;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  border-radius: 45.932px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(6.561670780181885px);
  padding: 6px 15px;
  align-items: center;
  position: absolute;
  bottom: 28px;
  right: 20px;
}

.profile-float-bottom svg {
  width: 12px;
  display: inline-block;
  margin-right: 6px;
}

.user-profile-meta .in-right h3 {
  margin: 0 0 0;
  font-size: 22px;
  color: #fff;
  font-weight: 500;
}

.user-profile-meta .in-right h4 {
  margin: 0 0 0;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}

.user-profile-meta .in-right p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  font-weight: 400;
  margin: 8px 0 0;
}

.user-profile-meta .in-right .upload-custom-btn span {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border-radius: 24.657px;
  border: 0.725px solid rgba(255, 255, 255, 0.3);
  padding: 10px 16px;
  cursor: pointer;
}

.user-profile-meta .in-right small {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 0;
  display: block;
  padding-top: 30px;
}

.user-profile-meta-with-padding {
  padding: 1.5rem 1rem;
}

/* PROFILE STYLE CLOSED */

/* SETTING STYLING */

.textarea-counter {
  position: absolute;
  right: 15px;
  bottom: 15px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 15px;
  font-weight: 400;
}

ul.setting-Preferences-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.setting-Preferences-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 26px;
}

ul.setting-Preferences-list li:last-child {
  padding-bottom: 0px;
}

ul.setting-Preferences-list li span {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  margin: 0 0 0;
  display: block;
}

ul.setting-Preferences-list li input {
  display: block;
  width: 18px;
  height: 18px;
}

.settingtabs-wrapper ul.nav-tabs {
  border: none;
}

.settingtabs-wrapper ul.nav-tabs li.nav-item {
  padding-bottom: 20px;
  padding-right: 30px;
}

.settingtabs-wrapper ul.nav-tabs li.nav-item .nav-link {
  color: rgba(255, 255, 255, 0.5);
  font-size: 25px;
  font-weight: 600;
  padding: 0 0 0;
  background: none;
  outline: none;
  text-decoration: none;
  border: none;
  font-family: "Roboto", serif;
}

.settingtabs-wrapper ul.nav-tabs li.nav-item .nav-link.active {
  color: #fff;
}

.settingtabs-wrapper ul.nav-tabs {
  border: none;
}

.settingtabs-wrapper ul.nav-tabs li.nav-item {
  padding-bottom: 20px;
  padding-right: 30px;
}

.settingtabs-wrapper ul.nav-tabs li.nav-item .nav-link {
  color: rgba(255, 255, 255, 0.5);
  font-size: 25px;
  font-weight: 600;
  padding: 0 0 0;
  background: none;
  outline: none;
  text-decoration: none;
  border: none;
  font-family: "Roboto", serif;
}

.settingtabs-wrapper ul.nav-tabs li.nav-item .nav-link.active {
  color: #fff;
}

ul.Preferences-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.Preferences-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8.703px;
  border: 0.725px solid rgba(255, 255, 255, 0.2);
  padding: 15px 15px;
  margin-bottom: 16px;
  cursor: pointer;
}

ul.Preferences-list li:last-child {
  margin-bottom: 0px;
}

ul.Preferences-list li span {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  font-weight: 500;
  display: block;
  font-family: "Roboto", serif;
}

/* SETTING STYLING CLOSED */

@media (max-width: 991.98px) {
  .header-inner ul li p {
    cursor: pointer;
    font-size: 15px;
    margin-bottom: 0px;
  }
  .header-inner ul li {
    padding: 10px 10px;
  }
  .header-inner .header-left a img {
    max-width: 120px;
  }
  .header-inner .header-left {
    width: 160px;
  }
  .header-inner .header-middle {
    width: calc(100% - 320px);
  }
  .header-inner .header-right {
    width: 160px;
    text-align: right;
  }

  ul.mesonate-list li {
    width: 50%;
  }
}

@media (max-width: 767.98px) {
  .header-mobile-toggle {
    /* display: block; */
  }
  .header-inner .header-middle {
    display: none;
  }
  .header-inner .header-right ul {
    /* display: none; */
    position: absolute;
    top: 80px;
    right: 20px;
    width: 160px;
    z-index: 11;
    border-radius: 10px;
    border: 0.5px solid rgba(254, 251, 234, 0.2);
    background: #323232;
    text-align: left;
  }
  .header-inner .header-right ul.active {
    display: block;
  }
  .header-inner .header-right ul li {
    display: block !important;
    border-bottom: 1px solid rgba(254, 251, 234, 0.3);
  }
  .header-inner .header-right ul li:last-child {
    border-bottom: none;
  }
  .header-inner {
    justify-content: space-between;
  }
  .header-inner ul li p {
    font-size: 15px;
    padding: 4px 6px;
    margin-bottom: 0px;
  }

  .site-heading h1 {
    font-size: 30px;
  }
  .site-heading h4 {
    font-size: 22px;
  }
  .custom-tabs-wrapper {
    padding: 30px 0 0px;
  }
  .custom-tabs-wrapper .nav-tabs .nav-item button {
    font-size: 18px;
  }
  .custom-tabs-wrapper .tab-content .nav-tabs .nav-item button,
  .mesonate-bottom-text h4 {
    font-size: 14px;
  }
  .mesonate-bottom-text p {
    font-size: 14px;
  }
  .mesonate-label {
    margin-bottom: 10px;
  }
  .discover-wrapper {
    padding: 30px 0;
  }
  .header-inner .header-left {
    width: auto;
  }
  .header-inner .header-right {
    width: auto;
  }
  .user-profile-meta .in-left img {
    width: 100px;
    height: 100px;
  }
  .user-profile-meta .in-left {
    width: 100px;
  }
  .user-profile-meta .in-right {
    width: calc(100% - 100px);
    padding-left: 15px;
  }
  .user-profile-meta .in-right h3 {
    font-size: 18px;
  }
  .profile-float-top {
    top: 7px;
    right: 6px;
  }

  .newsfeed-body ul li {
    display: block;
  }
  .newsfeed-body ul li .in-left,
  .newsfeed-body ul li .in-right {
    width: 100%;
  }
  .newsfeed-body ul li .in-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .news-feed-modal .modal-header .modal-title .pe-4 button {
    margin: 0;
  }
  .settng-profile-icon-header {
    position: absolute;
    top: 8px;
    right: 20px;
  }
}

.tags {
  border-radius: 34px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  font-size: 20px;
  font-weight: 400;
  padding: 6px 18px;
  display: inline-flex;
  align-items: center;
  font-family: "Roboto", serif;
}
.activeCategory {
  background-color: #ff8b3d !important;
  color: white !important;
  border-color: #ff8b3d !important;
}
.category {
  background-color: transparent !important;
  color: white !important;
  border-color: rgba(255, 255, 255, 0.3) !important;
}

.category:hover {
  cursor: pointer;
  border-color: #ff8b3d !important;
}

.input-password {
  position: absolute;
  top: 16px;
  right: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  /* className={`absolute inset-y-0 right-0 flex items-center px-3 text-gray-500 hover:text-gray-700 cursor-pointer`} */
}

.eye-icon {
  height: 20px;
  width: 20px;
}
.error-text {
  font-size: 14px;
  color: red;
  text-align: start;
}

.hamburger-active-desktop {
  position: absolute;
  /* top: 80px;
  right: 20px; */
  right: 0px;
  /* bottom: 0px; */
  top: 40px;
}
.hamburger-active-desktop ul li {
  z-index: 11;
  padding: 10px;
  /* border-radius: 10px; */
  /* border: 0.5px solid rgba(254, 251, 234, 0.2); */
  width: 160px;
  border-bottom: 1px solid rgba(254, 251, 234, 0.3);
  text-align: left;
}

.hamburger-active-desktop ul {
  width: 160px;
  background: #323232;
  border: 0.5px solid rgba(254, 251, 234, 0.2);
  border-radius: 10px;
}
.hamburger-active-desktop ul li:last-child {
  border-bottom: none;
}
.hamburger-active {
  position: absolute;
  /* top: 80px;
  right: 20px; */
  left: 50px;
  top: -30px;
}
.hamburger-active ul li {
  z-index: 11;
  padding: 10px;
  /* border-radius: 10px; */
  /* border: 0.5px solid rgba(254, 251, 234, 0.2); */
  width: 160px;
  border-bottom: 1px solid rgba(254, 251, 234, 0.3);
  text-align: left;
}

.hamburger-active ul {
  width: 160px;
  background: #323232;
  border: 0.5px solid rgba(254, 251, 234, 0.2);
  border-radius: 10px;
}
.hamburger-active ul li:last-child {
  border-bottom: none;
}

@media (max-width: 999px) {
  .desktop-header {
    display: none;
  }
  .mobile-header {
    display: block;
  }
  .header-mobile-logo {
    display: block;
  }
}
@media (min-width: 1000px) {
  .header-mobile-logo {
    display: none;
  }
  .mobile-header {
    display: none;
  }
  .desktop-header {
    display: flex;
  }
}

.hamburger-display {
  display: none;
}

p {
  margin-bottom: 0px !important;
}

.mesonate-grid div[draggable="true"] {
  /* e.g. prevent pointer events messing with layout: */
  pointer-events: all;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Basic style for the overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* semi-transparent dark background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure overlay is on top */
}

/* Loading spinner */
.spinner {
  border: 8px solid #f3f3f3; /* Light gray background */
  border-top: 8px solid #3498db; /* Blue color for the spinning part */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite; /* Spins the spinner */
}

/* Animation for spinning effect */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Your page content styling */
.content {
  padding: 20px;
  text-align: center;
}

.profile-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.profile-wrapper {
  padding-top: 60px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 130px;
}

@media (max-width: 700px) {
  .profile-wrapper {
    padding-top: 10px;
    padding-left: 4px;
    padding-right: 4px;
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 700px) {
  .profile-image-desktop {
    display: none;
  }
  .profile-image-mobile {
    display: block;
  }
}
@media (min-width: 700px) {
  .profile-image-desktop {
    display: block;
  }
  .profile-image-mobile {
    display: none;
  }
}

.activeTabWrapper {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.activeFeedTab {
  border-color: #ff8b3d !important;
}

.feedTab {
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  background: transparent;
  border: none;
  padding: 5px 0;
  outline: none;
  display: inline-block;
  border-bottom: 1px solid transparent;
  cursor: pointer;
}

.tabWrapper {
  padding: 20px 20px;
}

@media (max-width: 500px) {
  .activeTabWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

.discover-tab-border {
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
}

.text-theme-color {
  color: #ff8b3d;
}

.footer-height {
  margin-bottom: 100px;
  /* height: 100px; */
}
.footer-link {
  text-decoration: none;
  color: white;
  margin: 1rem 0;
}
.main-footer-container {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 4rem;
}

.email-text {
  color: #ffffffb3;
}

.mesonate-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 16px;
  row-gap: 16px;
  width: 100%;
  padding-left: 0;
  padding-top: 16px;
}
.mesonate-grid-profile {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 12px;
  row-gap: 16px;
  width: 100%;
  padding-left: 0px;
  padding-top: 16px;
}

@media (max-width: 499px) {
  .mesonate-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 12px;
    row-gap: 16px;
    width: 100%;
  }
  .main-footer-container {
    justify-content: start;
    flex-wrap: wrap;
  }
}
@media (min-width: 500) and (max-width: 1000px) {
  .mesonate-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 12px;
    row-gap: 16px;
    width: 100%;
  }
}

@media (max-width: 991.98px) {
  .mesonate-grid-profile {
    /* display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 12px;
    row-gap: 16px;
    width: 100%;
    padding-left: 0px;
    padding-top: 16px; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 12px;
    row-gap: 16px;
    width: 100%;
    padding-top: 16px;
  }
}

.card-wrapper {
  position: relative;
  /* background: white; */
  border-radius: 21px;
  overflow: hidden;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  /* transition: transform 0.2s; */
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(6.561670780181885px);
  box-shadow: 0px 10px 10px 1px rgba(0, 0, 0, 0.3);
}

.card-wrapper .mesonate-image img {
  object-fit: cover;
  width: 100%;
  display: block;
  height: 250px;
}

.card-wrapper.tall-img .mesonate-image img {
  height: 500px;
}

.mesonate-image-container {
  position: relative;
  width: 100%;
  height: 240px;
  overflow: hidden;
}

.tall-card {
  grid-row: span 2;
}

.tall-image {
  height: 496px;
  max-height: 496px;
}

@media (max-width: 991.98px) {
  .mesonate-image-container {
    height: 200px;
  }
  .mesonate-image-container.tall-image {
    height: 400px;
  }
  .tags {
    font-size: 13px;
  }
}

.confirmButton {
  color: #fff;
  border-color: #ff9a9a;
  font-size: 20px;
  font-weight: 500;
  background: transparent;
  padding: 15px;
  outline: none;
  display: inline-block;
  margin-top: 60px;
  border-radius: 30px;
}

.confirmButton.active {
  border-color: #ff9a9a;
}

.cancelButton {
  color: #fff;
  border-color: #343a40;
  font-size: 20px;
  font-weight: 500;
  background: transparent;
  padding: 15px;
  outline: none;
  display: inline-block;
  margin-top: 60px;
  border-radius: 30px;
}

.cancelButton.active {
  border-color: #ff8b3d;
}

.welcome-modal-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}

.welcome-modal-content-container {
  background-color: #313131;
  border-radius: 1.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  max-width: 32.5rem;
  text-align: center;
}

.welcome-modal-button {
  color: #fff;
  font-size: 21px;
  background: transparent;
  outline: none;
  border-radius: 14px;
  border: 0.5px solid #ff8b3d;
  padding: 10px 16px;
  font-weight: 400;
  display: inline-block;
  text-decoration: none;
  margin: 2.5rem 0 1.25rem;
}
.welcome-modal-button:disabled {
  color: gray;
}

.cursor-pointer {
  cursor: pointer;
}
.show-more-container {
  padding-top: 20rem;
  width: 100%;
  position: absolute;
  bottom: 12.5rem;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(41, 41, 41, 0) 49.88%,
    #1f1f1f 77.44%
  );
  align-items: center;
}

.button-container {
  background-color: #1f1f1f;
}

.primary-button {
  cursor: pointer !important;
  padding: 0.5rem 1rem;
  border-radius: 1.5rem;
  background-color: #ff8b3d;
  color: white;
  border: none;
  outline: none;
}

.outline-button {
  width: 100%;
  cursor: pointer !important;
  padding: 8px 16px;
  font-size: 22px;
  border-radius: 1.5rem;
  background-color: transparent;
  color: white;
  border: 0.5px solid #ff8b3d;
  outline: none;
}
.premium-banner-container {
  background-image: url("../public/images/pricingPage.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 830px;
  height: 198px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 1.2rem;
  background-color: #161616;
  overflow: hidden;
}

@media (max-width: 652px) {
  .premium-banner-container {
    background-size: auto 100%;
  }
}
.premium-banner-content-mask {
  padding: 3rem 8rem;
  background: linear-gradient(
    89.92deg,
    rgba(22, 22, 22, 0) 4.51%,
    rgba(22, 22, 22, 0.633422) 11.9%,
    rgba(22, 22, 22, 0.845552) 22.78%,
    rgba(22, 22, 22, 0.902254) 42.97%,
    #161616 65.99%
  );
}

.premium-banner-content-heading {
  font-size: 2.3rem;
}

.premium-banner-content-text {
  font-size: 1.5rem;
}

@media (max-width: 499px) {
  .premium-banner-container {
    width: 80vw;
    flex-wrap: wrap;
  }
  .premium-banner-content-mask {
    padding: 1rem;
  }
  .premium-banner-content-heading {
    font-size: 1.5rem;
  }
  .premium-banner-content-text {
    font-size: 1rem;
  }
  .premium-banner-action {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 1rem 0;
  }
}

@media (max-width: 800px) {
  .premium-banner-container {
    width: 80vw;
    justify-content: center;
    flex-wrap: wrap;
  }

  .premium-banner-content-mask {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
  }

  .premium-banner-content-heading {
    font-size: 1.5rem;
  }

  .premium-banner-content-text {
    text-align: center;
    font-size: 1rem;
  }

  .premium-banner-action {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 1rem 0;
  }
}

.upgrade-modal .modal-content {
  max-width: 536px;
  border-radius: 32px;
  background-color: #212121;
  position: relative;
}
.upgrade-modal .custom-modal-body {
  padding: 61px 43px;
}
.upgrade-modal .close-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  background: none;
  border: none;
  color: #fff;
  font-size: 25px;
  padding: 0;
  z-index: 99;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upgrade-modal .close-btn:hover {
  cursor: pointer;
}
.upgrade-modal .modal-title {
  font-size: 26px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  margin-bottom: 42px;
}
.upgrade-modal .modal-text {
  font-size: 22px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  margin-bottom: 42px !important;
}
.button-outline-custom.btn-custom {
  border: 1px solid #fefbea;
  border-radius: 14px;
  height: 40px;
  padding: 16px 10px;
  font-size: 17px;
  font-weight: 400;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.billing-field {
  display: flex;
  flex-direction: column;
  gap: 23px;
  min-height: 435px;
}

.main-field-box {
  position: relative;
  height: 59px;
  width: 100%;
  background-color: transparent;
  border: 1px solid rgba(254, 251, 234, 0.2);
  border-radius: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 22px;
}
.postion-label {
  /* position: absolute; */
  left: 22px;
  top: 16px;
  font-size: 21px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  z-index: 999;
  border-radius: 13px;
}
.field-input.form-control {
  width: 50%;
  min-width: 50%;
  height: 100%;
  background-color: #212121;
  border: none;
  padding: 10px 22px;
  display: flex;
  justify-content: flex-end;
  color: #fff;
  font-size: 21px;
  font-weight: 400;
  border-radius: 13px;
}
.field-input.form-control.custom-width-field {
  width: 25%;
  min-width: 25%;
}
.field-input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.field-input.form-control:focus {
  background-color: transparent;
  color: #fff;
  box-shadow: none;
}

.custom-radio-btn .custom-radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 22px;
  border: 1px solid #323232;
  border-radius: 8px;
  color: #fff;
  background-color: rgba(50, 50, 50, 0.9);
  position: relative;
  cursor: pointer;
  margin-bottom: 24px;
  transition: border-color 0.3s;
  font-size: 21px;
  font-weight: 500;
}
.custom-radio-btn .custom-radio:nth-child(2) {
  margin-bottom: 34px;
}

.custom-radio-btn .custom-radio.selected {
  border: 2px solid #ff8b3d;
  background-color: rgba(50, 50, 50, 0.3);
}

.custom-radio-btn .custom-radio input {
  display: none;
}

.custom-radio-btn .option-text {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.custom-radio-btn .discount-badge {
  background-color: #ff8b3d;
  color: #fff;
  font-size: 19px;
  font-weight: 400;
  border-radius: 10px;
  padding: 4px 12px;
  position: absolute;
  left: -10px;
  bottom: -25px;
}
.heading-plans-modal {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  margin: 21px auto 41px;
  text-align: center;
  max-width: 470px;
}

@media (max-width: 767px) {
  .settingtabs-wrapper ul.nav-tabs li.nav-item .nav-link {
    font-size: 16px;
  }
  .settingtabs-wrapper ul.nav-tabs li.nav-item {
    padding-bottom: 20px;
  }
}
@media (max-width: 575px) {
  .main-field-box {
    flex-direction: column;
    padding-left: 0;
    height: auto;
  }
  .field-input.form-control {
    width: 100%;
    padding: 10px 16px;
    font-size: 16px;
  }
  .main-field-box .mobile-field {
    flex-direction: column;
    width: 100%;
  }
  .field-input.form-control.custom-width-field {
    width: 100%;
    min-width: 100%;
  }
  .postion-label {
    padding-top: 10px;
  }
  .heading-plans-modal {
    font-size: 16px;
    margin: 0 0 21px;
  }
  .upgrade-modal .custom-modal-body {
    padding: 50px 20px;
  }
  .upgrade-modal .modal-title {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .upgrade-modal .modal-text {
    font-size: 18px;
    margin-bottom: 20px;
  }
}

.user-profile-settings-modal {
  max-width: 645px;
}

/* Recent Posts */
.news-container {
  width: 1080px;
  max-width: 100%;
  margin: 0 auto;
  padding: 2rem 1rem;
  background-color: #1f1f1f;
  color: white;
}

.news-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.go-to-blog-button {
  /* padding: 10px 0 10px 0; */
  color: #a1a1a1;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.go-to-blog-button:hover {
  color: #f2f2f2;
}

.news-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.news-title {
  font-size: 1.5rem;
  font-weight: 600;
}

.nav-buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.nav-button-right {
  margin-bottom: 10px;
  /* padding: 0.5rem; */
  border-radius: 9999px;
  transition: background-color 0.2s;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  position: left;
  font-size: x-large;
}

.slider-container {
  position: relative;
  overflow: hidden;
}

.slider-track {
  display: flex;
  transition: transform 0.5s ease-out;
  min-height: 250px;
}

.news-card {
  /* min-width: 300px; */
  padding: 0 0.5rem;
  cursor: pointer;
}

.card-content {
  position: relative;
  cursor: pointer;
}

.news-image {
  width: 100%;
  height: 170px;
  object-fit: cover;
  border-radius: 0.5rem;
}

.card-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, #292929, transparent);
  padding: 1rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.card-info {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.card-title {
  font-size: 1.125rem;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.card-meta {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: rgb(209, 213, 219);
  gap: 1rem;
}
/* Recent Posts End */

.newsfeed-tabs {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 30px;
}

.newsfeed-tabs button {
  background: none;
  border: none;
  font-size: 20.21px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  padding-bottom: 8px;
  cursor: pointer;
  position: relative;
  /* transition: color 0.3s ease-in-out; */
}

.newsfeed-tabs button.active {
  font-weight: 600;
  color: #ffffff;
}

.newsfeed-tabs button.active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background-color: #ff6600;
  /* transition: width 0.3s ease-in-out; */
}

.video-section {
  padding: 40px 0;
}

.video-section iframe {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.custom-event-modal h3 {
  font-family: "Roboto", serif;
  margin: 0 0 6px;
  font-weight: 600;
  font-size: 24px;
}
.box-form-input.mb-20 {
  margin-top: 20px;
}
.custom-event-modal .modal-dialog .modal-content {
  border-radius: 26.801px !important;
  background: #212121 !important;
  box-shadow: 0px 2.493px 13.962px 0px rgba(0, 0, 0, 0.25) !important;
  overflow: hidden;
}

.custom-event-modal .modal-dialog .modal-content .modal-body {
  padding: 60px 44px 44px;
}

.create-event-form .create-event-btn {
  display: block !important;
  width: 100% !important;
  margin: 0 !important;
  border-radius: 8.703px;
  border: 0.725px solid rgba(255, 255, 255, 0.2);
  background: #ffa467;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  line-height: 39px;
}

.mb-20 {
  margin-bottom: 20px !important;
}
.react-datepicker-wrapper {
  width: 100%;
}

.box-form-input {
  border-radius: 18.075px;
  border: 0.623px solid rgba(255, 255, 255, 0.21);
  background: rgba(255, 255, 255, 0.02);
  padding: 28px 22px;
}

.create-event-form input {
  font-family: "Roboto", serif !important;
  width: 100% !important;
  border: 0.725px solid rgba(255, 255, 255, 0.2) !important;
}

.box-form-input.box-form-input-grid {
  display: flex;
}
.grid-box-wrapper {
  border: none !important;
}

/* @media(max-width: 991.98px){
  .news-card{min-width: 33.33%;}
}

@media(max-width: 767.98px){
  .news-card{min-width: 50%;}
}
@media(max-width: 600.98px){
  .news-card{min-width: 100%;}
} */
.modal-header-section,
.modal-header-title-date {
  display: flex;
}

/* event image list */

.event-back-image {
  margin-bottom: 30px;
}

.event-back-image button {
  background: none;
  outline: none;
  box-shadow: none;
  border: none;
  color: #fff;
  font-family: "Roboto", serif;
  font-weight: 600;
  font-size: 24px;
  line-height: normal;
  padding: 0 0 0;
  position: relative;
  margin-left: -4px;
}

.event-back-image button svg {
  display: inline-block;
  margin-right: 10px;
}

.event-back-image button span {
  display: inline-block;
}

.event-image-search {
  margin-bottom: 20px;
}

.event-image-search input {
  border-radius: 8.703px;
  border: 0.725px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  background: transparent;
  height: 37px;
  color: rgba(255, 255, 255, 0.4);
  font-family: "Roboto", serif;
  font-weight: 500;
  font-size: 16px;
  padding: 0 11px;
  outline: none;
  box-shadow: none;
}

.search-event-image-box {
  border-radius: 18.075px;
  border: 0.623px solid rgba(255, 255, 255, 0.21);
  background: rgba(255, 255, 255, 0.02);
  padding: 20px;
}

.event-photos-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 410px;
  overflow: auto;
}

.event-photo-item {
  width: calc(50% - 16px);
  margin-bottom: 20px;
}

.event-photo-item img {
  max-width: 100%;
  display: inline-block;
  width: 100%;
  height: 180px;
  border-radius: 20px;
  object-fit: cover;
}

.upload-event-image {
  border-radius: 18px;
  border: 0.725px solid rgba(255, 255, 255, 0.2);
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.upload-event-image span {
  font-family: "Roboto", serif;
  font-weight: 500;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
}

.upload-event-image svg {
}

/* Scrollbar ko chhupane ke liye */
.search-event-image-box ::-webkit-scrollbar {
  width: 0px; /* Max 2px */
}

/* Scrollbar track ko transparent karne ke liye */
.search-event-image-box ::-webkit-scrollbar-track {
  background: transparent;
}

/* Scrollbar thumb ko transparent karne ke liye */
.search-event-image-box ::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

@media (max-width: 767.98px) {
  .d-flex.footer-btns {
    justify-content: center;
  }
  .container.main-footer-container {
    display: block;
    text-align: center;
  }
}
@media (max-width: 950px) {
  .modal-header-section {
    display: flow-root;
  }
  .modal-header-title-date {
    display: flow-root !important;
  }
  /* .news-feed-modal .modal-header .modal-title */
  .modal-header-title-date small {
    margin-left: 0 !important;
  }
  .update-event-follow-button {
    margin-bottom: 10px;
  }
}
.event-top-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 9.5vw 0;
}

.event-top-header .close-button {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s;
}
.upgrade-modal {
  gap: 40px;
  padding-bottom: 20px;
}
.upgrade-modal-text {
  padding: 0px 10px;
  font-size: 22px;
  font-weight: 400;
}
.upgrade-modal .close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s;
}
.upgrade-modal .close-button:hover {
  opacity: 0.8;
}
.event-top-header .close-button:hover {
  opacity: 0.8;
}
.event-container {
  padding: 0px 26vw;
}

@media (max-width: 1024px) {
  .event-container {
    padding: 0px 16vw;
  }
}

/* Follow Button Animation */
.icon-enter {
  opacity: 0;
  transform: scale(0.8);
}
/* Entering: final state */
.icon-enter.icon-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 200ms ease-in-out;
}

/* Exiting: initial state */
.icon-exit {
  opacity: 1;
  transform: scale(1);
}
/* Exiting: final state */
.icon-exit.icon-exit-active {
  opacity: 0;
  transform: scale(0.8);
  transition: all 200ms ease-in-out;
}
